import { CardProps } from './Card';

export const TIMELINE_CARDS: CardProps[] = [
  {
    imgUrl: './images/timeline/timeline-2.jpg',
    title: 'Lần đầu gặp nhau',
    description:
      'Tường nhà có hoa,\n Mắt em có nắng.\n Trời có mây trắng,\n Tôi thấy trong xanh.\n' +
        '\n' +
        'Từ ngày họ gặp nhau lần đầu, chàng trai đã bị cô gái thu hút ngay từ cái nhìn đầu tiên, như một sự "nảy mầm" trong trái tim Chàng. Nhưng…\n' +
        '\n' +
        '“Lời yêu dễ nói. Ai sẽ đủ kiên nhẫn để chứng minh?”\n',
  },
  {
    imgUrl: './images/timeline/timeline-1.jpg',
    title: 'Khởi đầu tình yêu',
    date: '25.01.2020',
    description:
      'Chàng Sài Gòn, Nàng Đồng Nai, không xa cũng chẳng gần. Nàng thức dậy lúc 5h sáng, chạy 20km đi làm và công ty không cho dùng điện thoại. Chàng làm đêm, mặt trời lên gần ngọn cây mới lò dò ra khỏi nhà. Ấy vậy mà để được chat với nhau vài câu ngắn ngủi, chúc nhau một ngày mới tốt lành. Chàng đã tập dậy sớm, còn Nàng dù trễ làm cũng tranh thủ gửi vội vài tin nhắn.\n' +
        '\n' +
        'Giống như nỗi nhớ, chờ đợi, khắc khoải, hy vọng và vỡ oà. Niềm vui là những tin nhắn vội vàng, những cuối tuần hiếm hoi gặp gỡ. Và sau 7749 lần bị từ chối, từ lần này đến lần khác bởi nàng với sự nhạy cảm đặc biệt của mình...\n Dưới ánh đèn lung linh và tiếng pháo hoa vang vọng của đêm giao thừa, Nàng đã gật đầu đồng ý, và từ đó câu chuyện tình yêu của họ bắt đầu...\n',
  },
  {
    imgUrl: './images/timeline/timeline-3.JPG',
    title: 'Thành viên mới',
    date: '04.09.2020',
    description:
      'Sau gần 1 năm bên nhau, bằng ánh mắt trong sáng và đáng yêu này, đã khiến cả hai "ngây thơ" đón nhận một đứa con đầu lòng "vô số tội". Chàng và Nàng quyết định đặt tên cho bé là Tabby. \n' +
        '\n' +
        'Tới nay Tabby đã được hơn 2 tuổi, ngoài cái ăn nhiều, lười tập thể dục thể thao và dễ quạu, thì bé còn được thêm một ưu điểm là "chảnh mèo" nữa 😹.',
  },
  {
    imgUrl: './images/timeline/timeline-4.jpg',
    title: 'Nàng đồng ý!',
    date: '12.11.2022',
    description:
      'Đà Lạt một ngày mưa, Chàng đã lên kế hoạch cùng những người bạn thân để tổ chức một buổi tối thật bất ngờ và... Nàng đã "gật đầu đồng ý!” cùng Chàng về chung một nhà. Đà Lạt mưa lạnh, nhưng nụ cười cả hai nhìn nhau ấm áp đến lạ.',
  },
  {
    imgUrl: './images/timeline/timeline-5.jpg',
    title: 'Chuẩn bị cho đám cưới',
    // date: '12.11.2022',
    description:
        'Có lần Nàng tựa đầu vào vai Chàng và nói: Chỉ cần một buổi tiệc, nhỏ thôi, vài người thân và bạn bè tri kỷ chứng giám. Cùng người nắm tay, nguyện ở bên nhau vượt qua giông bão hết cuộc đời. Mong một đời bình an sau này, thế là đủ.\n' +
        '\n' +
        'Chàng hôn nhẹ vào trán: Tổ chức đám cưới thì mệt thật, nhưng mà như thế thì lại càng thích. Sống vốn đã chẳng dễ, tại sao cưới cứ phải xuề xòa. Tựu chung là, tính Chàng vốn đã không quan tâm thứ gì thì sẽ mặc kệ. Đã để tâm, chắc chắn sẽ đòi hỏi phải thật vừa lòng. \n' +
        '\n' +
        'Nàng đừng hòng có cơ hội mặc váy cưới không đẹp 😋.\n',
  },
];
