import useViewportWidth from 'hooks/useViewportWidth';
import Card from './Card';
import { TIMELINE_CARDS } from './constants';
import './styles.css';
import LazyBackgroundImage from 'components/LazyBackgroundImage';
import { HeartFilled } from '@ant-design/icons';
import CircleCard from './CircleCard';

export default function GroomAndBride() {
  const windowWidth = useViewportWidth();

  return (
    <LazyBackgroundImage
      src="/images/background-top.png"
      className="bg-contain bg-top bg-no-repeat relative"
    >
      <div className="section relative h-fit">
        <h1 className="section-title fade-in">Hành trình yêu</h1>
        <h3>2020-2023</h3>
        <div className="section-description fade-in">
          Có 1 đoạn mở đầu thế này: Sài Gòn, những ngày cuối năm, cái mùa đẹp đến đổi bạn có thể nghe gió hát giữa lòng thành phố. Có một Nàng thì sôi nổi, hoạt bát, còn Chàng thì trầm lặng, gai góc, vậy mà lại tìm thấy nhau. Để rồi cùng nhau tạo nên một chuyện tình nhẹ nhàng, bình lặng, như bức tranh từ một trò chơi ghép hình, nàng và chàng chắp lại từ những điều li ti bé nhỏ.
        </div>
        <div className="flex flex-col items-center lg:flex-row">
          <CircleCard
            imgUrl="./images/groom.jpeg"
            title="Ngọc Hoàng"
            subtitle="Chú rể"
            imgPosition="right"
          />
          <div className="circle flex justify-center items-center">
            <HeartFilled className="text-[#826542]" />
          </div>
          <CircleCard
            imgUrl="./images/bride.jpeg"
            subtitle="Cô dâu"
            title="Tú Trinh"
            imgPosition="left"
          />
        </div>

        <div
          className={`flex flex-col items-center gap-8 ${
            windowWidth >= 768 && 'frame'
          }`}
        >
          {TIMELINE_CARDS.map((card, index) => (
            <Card
              {...card}
              key={card.title}
              direction="horizontal"
              imagePosition={index % 2 === 0 ? 'left' : 'right'}
            />
          ))}
          <h3 className="text-center">The story is to be continued...</h3>
        </div>
      </div>
    </LazyBackgroundImage>
  );
}
