import { useMemo, useState } from 'react';
import './styles.css';
import Modal from 'components/Modal';
import { range } from 'lodash';
import Carousel from 'react-spring-3d-carousel';
import { CloseOutlined } from '@ant-design/icons';
import Gallery, { Image } from './Gallery';

type AlbumName = 'nha-hat' | 'song-sg' | 'ben-thanh' | 'catcat' | 'tabby' | 'xymon' | 'sapa';

const albumDict: Record<AlbumName, number> = {
  'nha-hat': 14,
  'song-sg': 18,
  catcat: 27,
  'ben-thanh': 14,
  tabby: 22,
  xymon: 50,
  sapa: 13
};

export default function Album() {
  const [open, setOpen] = useState(false);

  const [goToSlide, setGoToSlide] = useState(0);
  const [selectedAlbum, setSelectedAlbum] = useState<AlbumName>();

  const numberOfSlides = albumDict[selectedAlbum ?? 'ben-thanh'];

  const slides = useMemo(
    () =>
      range(numberOfSlides).map((index) => ({
        key: index,
        content: (
          <img
            src={`/images/${selectedAlbum}/${index + 1}.jpg`}
            alt={selectedAlbum}
          />
        ),
        onClick: () => setGoToSlide(index),
      })),
    [numberOfSlides, selectedAlbum],
  );

  function onClickCard(album: AlbumName) {
    setSelectedAlbum(album);
    setOpen(true);
    document.body.style.overflow = 'hidden';
  }

  function onCloseAlbum() {
    setOpen(false);
    document.body.style.overflow = 'auto';
  }

  const IMAGES: Image[] = [
    {
      src: '/images/xymon/1.jpg',
      numberOfPhotos: albumDict['xymon'],
      description: 'Oxy & Mon',
      onClick: () => onClickCard('xymon'),
      stretch: 'big',
    },
    {
      src: '/images/ben-thanh/1.jpg',
      numberOfPhotos: albumDict['ben-thanh'],
      description: 'Bến Thành',
      onClick: () => onClickCard('ben-thanh'),
      // stretch: 'big',
    },
    {
      src: '/images/song-sg/1.jpg',
      numberOfPhotos: albumDict['song-sg'],
      description: 'Sông Sài Gòn',
      onClick: () => onClickCard('song-sg'),
      // stretch: 'big',
      stretch: 'horizontal',
    },
    {
      src: '/images/nha-hat/1.jpg',
      numberOfPhotos: albumDict['nha-hat'],
      description: 'Nhà hát thành phố',
      onClick: () => onClickCard('nha-hat'),
      // stretch: 'horizontal',
    },
    {
      src: '/images/catcat/1.jpg',
      numberOfPhotos: albumDict['catcat'],
      description: 'Bản Cát Cát',
      onClick: () => onClickCard('catcat'),
      stretch: 'horizontal',
    },
    {
      src: '/images/sapa/1.jpg',
      numberOfPhotos: albumDict['sapa'],
      description: 'Sapa',
      onClick: () => onClickCard('sapa'),
      stretch: 'horizontal',
    },
    {
      src: '/images/tabby/1.jpg',
      numberOfPhotos: albumDict['tabby'],
      description: 'Tabby',
      onClick: () => onClickCard('tabby'),
    },
  ];

  return (
    <>
      <Gallery images={IMAGES} />
      <Modal visible={open}>
        <CloseOutlined
          className="absolute top-10 right-10 cursor-pointer z-50 text-white text-2xl"
          onClick={onCloseAlbum}
        />
        <Carousel
          showNavigation={false}
          goToSlide={goToSlide}
          slides={slides}
          offsetRadius={2}
          animationConfig={{
            tension: 120,
            fiction: 14,
          }}
        />
      </Modal>
    </>
  );
}
